var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(_vm.titleSet.mainTitle.show)?_c('div',{staticClass:"main-title",style:({ color: _vm.titleSet.mainTitle.color })},[_vm._v(" "+_vm._s(_vm.titleSet.mainTitle.value)+" ")]):_vm._e(),(_vm.titleSet.subTitle.show)?_c('div',{staticClass:"sub-title",style:({ color: _vm.titleSet.subTitle.color })},[_vm._v(" "+_vm._s(_vm.titleSet.subTitle.value)+" ")]):_vm._e(),_c('div',{staticClass:"form-box"},[_c('div',{staticClass:"input-items"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formData.name),expression:"formData.name",modifiers:{"trim":true}}],staticClass:"input-ele",style:({
          'background-color': _vm.colorSet.inputBgColor,
          'border-color': _vm.colorSet.inputBorderColor
        }),attrs:{"type":"text","placeholder":"请输入姓名","maxlength":"8"},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"input-items"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.phone),expression:"formData.phone"}],staticClass:"input-ele",style:({
          'background-color': _vm.colorSet.inputBgColor,
          'border-color': _vm.colorSet.inputBorderColor
        }),attrs:{"type":"number","placeholder":"请输入手机号","maxlength":"20"},domProps:{"value":(_vm.formData.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "phone", $event.target.value)}}})]),_c('div',{staticClass:"input-items"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.verify),expression:"formData.verify"}],staticClass:"input-ele",style:({
          'background-color': _vm.colorSet.inputBgColor,
          'border-color': _vm.colorSet.inputBorderColor
        }),attrs:{"type":"text","placeholder":"请输入验证码","maxlength":"8"},domProps:{"value":(_vm.formData.verify)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "verify", $event.target.value)}}}),_c('span',{staticClass:"verify-text",style:({ color: _vm.colorSet.mainColor }),on:{"click":_vm.getVerify}},[_vm._v(_vm._s(_vm.verifyText))])])]),_c('van-button',{staticClass:"submit-btn",style:({
      'background-color': _vm.colorSet.mainColor,
      'border-color': _vm.colorSet.mainColor
    }),attrs:{"type":"primary","loading":_vm.btnLoading,"loading-type":"spinner"},on:{"click":_vm.submitData}},[_vm._v("提交")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }