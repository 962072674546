<!--
 * @Date: 2021-11-11 20:30:30
 * @LastEditors: YuWenYun
 * @LastEditTime: 2021-12-20 19:39:38
 * @Description: 投票组件
 * @version: 
 * @Author: YuWenYun
-->
<template>
  <div class="qk-vote" ref="vote">
    <!-- 标题 -->
    <div v-if="vote.content.title.length" class="main-title" :style="{ color: vote.colorStyle.title }">
      {{ vote.content.title }}
    </div>
    <!-- 副标题 -->
    <div v-if="getIsShow" class="sub-title" :style="{ color: vote.colorStyle.subhead }">
      <span v-show="vote.content.subhead.votePeople">
        <span>{{ participateNum }}</span>
        人参与,
      </span>
      <span v-show="vote.content.subhead.isTime"> 距离活动结束还有{{ getResidueTime }} </span>
    </div>
    <!-- 选项列表 -->
    <ul class="content-cards" v-if="vote.type === '0'">
      <li v-for="(item, index) in vote.content.contentList" :key="index + 'i'" class="card" @click="chooseVote(item)">
        <section
          style="display:flex;justify-content: center;box-sizing: border-box;"
          :class="{
            borderClass: vote.isChoose === '1' && item.isChooseVote
          }"
          :style="{
            border: getBorder(item),
            borderRadius: vote.borderadius / 2 + '%'
          }"
        >
          <div class="tags" v-show="item.isVote">已投</div>
          <div
            style="background-color:#eeeeee;overflow:hidden;width:100%;height:100%;display:flex;justify-content: center;align-items:center"
            :style="{
              borderRadius: vote.borderadius / 2 + '%'
            }"
          >
            <img
              :src="item.img ? item.img : defaultImage"
              alt=""
              :id="item.id"
              :style="{
                width: imageWHs[index] && imageWHs[index].newWidth,
                height: imageWHs[index] && imageWHs[index].newHeight
              }"
              @load="loadHandle($event, index, item.id)"
            />
          </div>
          <div class="tips">
            <div class="video-play" v-show="isShowLitterPlay(item)">
              <svg-icon iconName="video" :iconWidth="14" :iconHeight="14"></svg-icon>
            </div>
            <div class="votes" v-show="isShow">{{ item.optionNum || 0 }}票</div>
          </div>

          <!-- 中间的播放按钮 -->
          <div class="big-play" v-show="isShowBigPlay(item)" @click.stop="playVideo(item)">
            <svg-icon iconName="video" :iconWidth="32" :iconHeight="32"></svg-icon>
          </div>
        </section>

        <p :style="{ color: vote.colorStyle.option }">{{ item.text }}</p>
      </li>
    </ul>
    <ul class="content-list" v-else>
      <!-- 单选 -->
      <template v-if="vote.isChoose === '0'">
        <el-radio-group v-if="!isParticipate" v-model="radio" @change="changeHandle" style="overflow: hidden">
          <el-radio
            :label="item.id"
            v-for="item in vote.content.contentList"
            :key="item.id"
            class="radio-class"
            :style="{
              borderRadius: (vote.borderadius / 200) * 41 + 'px',
              color: vote.colorStyle.option
            }"
          >
            {{ item.text }}
            <div
              style="font-weight: 400;
              color: rgba(0, 0, 0, 0.64);
              line-height: 14px;"
              v-show="isShow"
            >
              {{ item.optionNum || 0 }}
            </div>
          </el-radio>
        </el-radio-group>
        <ul v-else style="overflow: hidden">
          <li
            class="radio-class-isParticipate"
            v-for="item in vote.content.contentList"
            :key="item.id"
            :style="{
              borderRadius: (vote.borderadius / 200) * 41 + 'px',
              color: vote.colorStyle.option
            }"
          >
            <section class="radio-head">
              <span>
                <svg-icon v-show="item.isVote" icon-name="hadChoose" :iconWidth="16" :iconHeight="16"></svg-icon>
                {{ item.text }}</span
              >
              <div
                style="font-weight: 400;
              color: rgba(0, 0, 0, 0.64);
              line-height: 14px;"
                v-show="isShow"
              >
                {{ item.optionNum ? item.optionNum + '(' + getPercentage(item) + '%)' : 0 + '(' + (0 + '%)') }}
              </div>
            </section>
            <div class="speed">
              <el-progress :percentage="getPercentage(item)" :show-text="false"></el-progress>
            </div>
          </li>
        </ul>
      </template>
      <!-- 多选 -->
      <template v-else>
        <el-checkbox-group v-if="!isParticipate" v-model="voteIds" style="overflow: hidden">
          <el-checkbox
            :label="item.id"
            v-for="item in vote.content.contentList"
            :key="item.id"
            class="radio-class"
            :style="{
              borderRadius: (vote.borderadius / 200) * 41 + 'px',
              color: vote.colorStyle.option
            }"
            >{{ item.text }}
            <div
              style="font-weight: 400;
              color: rgba(0, 0, 0, 0.64);
              line-height: 14px;"
              v-show="isShow"
            >
              {{ item.optionNum ? item.optionNum + '(' + getPercentage(item) + '%)' : 0 + '(' + (0 + '%)') }}
            </div>
          </el-checkbox>
        </el-checkbox-group>
        <ul v-else style="overflow: hidden">
          <li
            class="radio-class-isParticipate"
            v-for="item in vote.content.contentList"
            :key="item.id"
            :style="{
              borderRadius: (vote.borderadius / 200) * 41 + 'px',
              color: vote.colorStyle.option
            }"
          >
            <section class="radio-head">
              <span>
                <svg-icon v-show="item.isVote" icon-name="hadChoose" :iconWidth="16" :iconHeight="16"></svg-icon>
                {{ item.text }}</span
              >
              <div
                style="font-weight: 400;
              color: rgba(0, 0, 0, 0.64);
              line-height: 14px;"
                v-show="isShow"
              >
                {{ item.optionNum ? item.optionNum + '(' + getPercentage(item) + '%)' : 0 + '(' + (0 + '%)') }}
              </div>
            </section>
            <div class="speed">
              <el-progress :percentage="getPercentage(item)" :show-text="false"></el-progress>
            </div>
          </li>
        </ul>
      </template>
    </ul>
    <!-- 视频播放 -->
    <van-popup
      v-model="videoModle"
      v-if="videoModle"
      get-container="body"
      :close-on-click-overlay="false"
      :overlay-style="{
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
      }"
    >
      <div
        class="video-content"
        style="background-color:transparent"
        :style="{
          width: innerWidth + 'px',
          height: innerHeight + 'px'
        }"
        @click.self="videoModle = false"
      >
        <div class="header">
          <span>{{ activeVideo.name.split('.')[0] }}</span>
          <div class="close" @click="videoModle = false">
            <svg-icon iconName="close" :iconWidth="24" :iconHeight="24"> </svg-icon>
          </div>
        </div>
        <div
          style="background:#000000;height:100%"
          :style="{
            width: innerWidth + 'px'
          }"
          class="video"
        >
          <video :src="activeVideo.url" v-if="activeVideo.url" controls :disablePictureInPicture="true" :playsinline="true" :webkit-playsinline="true" :x5-video-player-fullscreen="true" x5-video-player-type="h5" preload="auto" style="height:100%" :width="innerWidth" ref="videoRef"></video>
        </div>
      </div>
    </van-popup>
    <!-- 按钮 -->
    <button
      class="vote-button"
      :style="{
        backgroundColor: !isParticipate ? vote.colorStyle.main : '#F7F7F7',
        borderRadius: (vote.borderadius / 200) * 41 + 'px',
        width: vote.type === '0' ? this.element.commonStyle.width + 'px' : '130px',
        color: !isParticipate ? '#FFFFFF' : '#CFCFCF',
        ...fixedStyle
      }"
      @click="toVote"
      :id="element.uuid"
    >
      {{ !isParticipate ? vote.voteButtonText : vote.haveVoteButtonText }}
    </button>
  </div>
</template>
<script>
import * as dayjs from 'dayjs';
import api from '@/api/h5page/index.js';
import svgIcon from '../../../assets/icons/svgIcon.vue';
export default {
  components: { svgIcon },
  name: 'QkVote',
  props: {
    vote: {
      type: Object,
      default: () => {
        return {
          type: '0', // 0 卡片 1列表
          isChoose: '0', // 0 单选 1多选
          isShowPeople: '0', // 0投票后显示 1始终显示
          colorStyle: {
            main: 'rgba(77, 120, 255, 1)', // 主题颜色
            option: 'rgba(51, 51, 51, 1)', // 选项颜色
            title: 'rgba(51, 51, 51, 1)', // 标题颜色
            subhead: 'rgba(127, 127, 127, 1)' // 副标题颜色
          },
          borderadius: 10, // 圆角
          voteButtonText: '投票', // 投票的按钮文案
          haveVoteButtonText: '已投票', // 投票后的按钮文案
          content: {
            title: '以下动漫人物谁的人气最高（单选）', // 标题的文案
            subhead: {
              votePeople: true, // 显示投票人数
              isTime: true //显示截至时间
            },
            contentList: []
          },
          limitCount: '1', // 1每人一次 2每人一天一次
          limitStartTime: dayjs().format('YYYY-MM-DD HH:mm'), // 开始时间
          limitEndTime: dayjs()
            .add(7, 'day')
            .format('YYYY-MM-DD HH:mm') // 结束时间
        };
      }
    },
    element: Object,
    pageIndex: Number
  },
  inject: ['fatherNode'],
  data() {
    return {
      radio: '0',
      checkList: [],
      imageWHs: [], //图片宽高数组
      defaultImage: 'http://osres.wangxiaobao.com/h5editor/defaultImage.png',
      isNext: false, // 是否可以继续
      isParticipate: 0, // 是否参与过 0 是没有参与 1是参与过,这里的默认值给1是因为当用户没有授权的时候就不能投票、不能选择选项
      voteCount: 0, // 总票数
      participateNum: 0, // 参与人数
      voteIds: [], // 投票选项
      activeVote: '', // 被选中的选项id
      isShowPeople: false, // 是否显示票数
      videoModle: false, // 是否展示视频模块的弹出层
      activeVideo: {
        name: '', // 视频，名称
        url: '' // 视频地址
      }, // 被选中的视频的链接
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
      fixed: false,
      isEval: true // 是否继续执行
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    voteIds: {
      handler(val) {
        console.log(11111);
        if (val.length >= 1 && this.isEval) {
          this.fixedHandle();
        }
      },
      deep: true,
      immediate: false
    }
  },
  methods: {
    fixedHandle() {
      let top, height;
      this.isEval = false;
      // 判断投票按钮的展示位置
      this.$nextTick(() => {
        let buttonDom = document.getElementById(this.element.uuid);
        top = buttonDom.offsetTop + this.element.commonStyle.top;
        height = buttonDom.getBoundingClientRect().height;
        if (top > window.innerHeight - height && this.fatherNode.pages[this.pageIndex].type === 'lang') {
          // 固定定位
          this.fixed = true;
        } else {
          this.fixed = false;
        }
        this.$bus.$on('langScroll', scroll => {
          // 如果滑动的页面与当前页面是一个页面就执行下列逻辑
          if (scroll.index === this.pageIndex) {
            // 当页面滚动到一定时，下面提交的按钮就取消fixed，并跟随主体走
            let show = top - window.innerHeight - height;
            // console.log(scroll.scrollTop);
            if (show < scroll.scrollTop) {
              this.fixed = false;
            } else {
              this.fixed = true;
            }
          }
        });
      });
    },
    voteIdsChange(val) {
      console.log(val);
    },
    /**
     * @description: 选择选项交互
     * @param {*} item 选项信息
     * @return {*}
     */

    chooseVote(item) {
      // 如果参与过活动就不能选择
      if (this.isParticipate) {
        return;
      }
      // 多选
      if (this.vote.isChoose === '1') {
        if (this.voteIds.length && this.voteIds.includes(item.id)) {
          // 如果包含选项id就 取消该选项
          this.voteIds = this.voteIds.filter(i => i !== item.id);
          item.isChooseVote = false;
        } else {
          // 存储
          this.voteIds.push(item.id);
          item.isChooseVote = true;
        }
      } else {
        // 单选
        this.activeVote = item.id;
        this.$set(this.voteIds, 0, item.id);
        // this.voteIds[0] = item.id;
      }
      this.$forceUpdate();
    },
    /**
     * @description: 初始化数据·
     * @param {*}
     * @return {*}
     */

    async init() {
      let params = this.$route.query;
      // 判断票数是否一开始显示
      this.isShowPeople = this.vote.isShowPeople === '1';
      if (params.from && (params.from === 'draft' || params.from === 'preview')) {
        // 预览或者草稿不做数据处理 一切按照默认的来
        this.isNext = false;
      } else {
        this.getVoteInfo();
        // if(res.code!==)
      }
    },
    async getVoteInfo() {
      try {
        // 需要走授权
        this.isNext = true;
        // 获取投票数据
        let res = await api.getUserVote({
          entityId: this.$route.query.cmeId,
          voteId: this.element.uuid
        });

        this.vote.content.contentList = res.optionList;
        this.isParticipate = res.isParticipate;
        this.voteCount = res.voteCount;
        this.participateNum = res.participateNum;
      } catch (error) {
        console.log(error, 'ERROR');
        this.$toast(error.msg || '网络错误');
      }
    },
    /**
     * @description: 动态获取款高
     * @param {*} e 事件对象
     * @param {*} idName id名称
     * @return {*}
     */

    laodHanlde(e, idName = 'playerCont') {
      // 获取图片DOM
      let naturalHeight, naturalWidth;
      // 兼容 safrai不支持e.path语法
      if (Object.keys(e).includes('path')) {
        naturalHeight = e.path[0].naturalHeight;
        naturalWidth = e.path[0].naturalWidth;
      } else {
        naturalHeight = e.composedPath()[0].naturalHeight;
        naturalWidth = e.composedPath()[0].naturalWidth;
      }

      let newWidth, newHeight;
      if (naturalHeight == naturalWidth || naturalHeight > naturalWidth) {
        // eslint-disable-line
        newWidth = 'auto';
        newHeight = document.getElementById(idName).clientHeight + 'px';
      }
      if (naturalHeight < naturalWidth) {
        newHeight = 'auto';
        newWidth = document.getElementById(idName).clientWidth + 'px';
      }
      return {
        newWidth,
        newHeight
      };
    },
    /**
     * @description: 图片加载函数
     * @param {*} e 事件函数
     * @param {*} index 索引
     * @return {*}
     */

    loadHandle(e, index, id) {
      // console.log();
      let { newWidth, newHeight } = this.laodHanlde(e, id);
      this.imageWHs[index] = {
        newWidth,
        newHeight
      };
      this.$forceUpdate();
    },
    /**
     * @description: 获取高度
     * @param {*}
     * @return {*}
     */

    // getHeight() {
    //   let dom = this.$refs.vote;
    //   if (dom) {
    //     let height = getComputedStyle(dom).height.replace('px', '') - 0;
    //     this.activeElement.commonStyle.height = height;
    //   }
    // },
    /**
     * @description: 存储选项的id
     * @param {*} val
     * @return {*}
     */

    changeHandle(val) {
      if (this.vote.isChoose === '0') {
        // this.voteIds[0] = val;
        this.$set(this.voteIds, 0, val);
      }
    },
    /**
     * @description: 投票
     * @param {*}
     * @return {*}
     */

    async toVote() {
      try {
        if (this.isParticipate || !this.isNext) {
          if (!this.isParticipate) {
            // 已投票并且 要授权

            this.$toast('请在微信浏览器下授权后继续操作');
          }
          return;
        }
        if (!this.voteIds.length) {
          this.$toast('请选择投票选项');
          return;
        }
        await api.toVote({
          entityId: this.$route.query.cmeId,
          voteId: this.element.uuid,
          optionList: this.voteIds
        });
        // 刷新页面
        this.getVoteInfo();
        this.$toast('投票成功');

        // 清空数据
        this.voteIds = [];
        this.activeVote = '';
        this.isNext = false;
        if (this.vote.isShowPeople === '0') {
          this.isShowPeople = true;
        }
      } catch (error) {
        console.log(error, 'ERROR');
        this.$toast(error.msg || '网络错误');
      }
    },
    /**
     * @description: 播放投票上的选项
     * @param {*} item 选项
     * @return {*}
     */

    playVideo(item) {
      this.videoModle = true;
      // this.$refs.videoRef.play();
      this.activeVideo.url = item.video;
      this.activeVideo.name = item.videoName;
      this.$nextTick(() => {
        this.$refs.videoRef.play();
        // console.log(this.$refs.videoRef, 'this.$refs.videoRef');
      });
    }
  },
  computed: {
    fixedStyle() {
      if (this.fixed) {
        let listLeft = this.element.commonStyle.left + (this.element.commonStyle.width / 2 - 65) + 'px';
        let left = this.vote.type === '0' ? this.element.commonStyle.left + 'px' : listLeft;
        return {
          position: 'fixed',
          bottom: '40px',
          left
        };
      } else {
        return {};
      }
    },
    //   是否展示副标题
    getIsShow() {
      if (this.vote.content.subhead.votePeople || this.vote.content.subhead.isTime) {
        return true;
      } else {
        return false;
      }
    },
    // 获取剩余时间
    getResidueTime() {
      let newData = dayjs().valueOf();
      let residueTime = (dayjs(this.vote.limitEndTime).valueOf() - newData) / 1000;
      console.log(residueTime, 'residueTime----');
      if (residueTime > 0) {
        // 算天
        let diffDay = parseInt(residueTime / 86400);
        let hours = parseInt(residueTime / 3600) - 24 * diffDay;
        let minutes = parseInt((residueTime % 3600) / 60);
        return `${diffDay}天${hours}小时${minutes}分钟`;
      } else {
        return `0天0小时0分钟`;
      }
    },
    getBorder() {
      return function(item) {
        return this.activeVote === item.id ? '2px solid #4d78ff' : '2px solid transparent';
      };
    },
    isShow() {
      // 如果没有投票 就走正常的配置逻辑
      if (!this.isParticipate) {
        return this.isShowPeople;
      } else {
        // 如果投了票就
        return true;
      }
    },
    getPercentage() {
      return function(item) {
        return Math.floor((item.optionNum * 100) / this.voteCount);
      };
    },
    // 是否显示小的播放按钮
    isShowLitterPlay() {
      return function(item) {
        if (this.vote.isChoose === '1') {
          // 多选
          return this.vote.type === '0' && item.video && !this.voteIds.includes(item.id);
        } else {
          return this.vote.type === '0' && item.video && this.activeVote !== item.id;
        }
      };
    },
    // 是否显示大的播放按钮
    isShowBigPlay() {
      return function(item) {
        if (this.vote.isChoose === '1') {
          // 多选
          // console.log(item);
          return this.voteIds.includes(item.id) && item.video && this.vote.type === '0';
        } else {
          // 单选
          return this.activeVote === item.id && item.video && this.vote.type === '0';
        }
      };
    }
  }
};
</script>
<style lang="less" scoped>
.borderClass {
  border: 2px solid #4d78ff !important;
}
.van-popup--center {
  background: transparent !important;
}
.main-title {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  line-height: 22px;
  text-align: left;
  margin-bottom: 4px;
}
.sub-title {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 14px;
  text-align: left;
  margin-bottom: 24px;
}
.content-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 25px;

  .card {
    width: 150px;
    section {
      position: relative;
      height: 182px;
      img {
        width: 100%;
        height: 180px;
        border: 1px solid #dadada;
      }
      .tags {
        position: absolute;
        top: 0;
        left: 0;
        padding: 3px 6px;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        background: #ff8c8c;
        border-radius: 1px 0px 8px 0px;
      }
      .big-play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .tips {
        position: absolute;
        bottom: 12px;
        right: 12px;
        display: flex;
        align-items: center;

        .video-play {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.4);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .votes {
          height: 21px;
          line-height: 21px;
          background: rgba(0, 0, 0, 0.4);
          border-radius: 2px;
          padding: 2px 6px;
          font-size: 12px;
          color: #ffffff;
          margin-left: 8px;
        }
      }
    }
    p {
      text-align: center;
      height: 31px;
      line-height: 31px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      padding: 0 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
  }
}
.content-list {
  margin-bottom: 16px;
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner,
  /deep/ .el-radio__input.is-checked .el-radio__inner {
    background: #4d78ff;
    border-color: #4d78ff;
    box-shadow: none !important;
  }

  /deep/.el-progress-bar__inner {
    background: #4d78ff;
  }
  /deep/.el-checkbox__input.is-checked + .el-checkbox__label,
  /deep/.el-radio__input.is-checked + .el-radio__label {
    color: inherit;
  }
  /deep/.el-checkbox-group,
  /deep/.el-radio-group {
    width: 100%;
    .radio-class {
      box-sizing: border-box;
      width: 100%;
      height: 55px;
      background: #ffffff;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
      display: flex;
      align-items: center;
      padding: 0 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      margin-bottom: 16px;
      .el-checkbox__label,
      .el-radio__label {
        padding-left: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}
.video-content {
  position: relative;
  .header {
    position: absolute;
    padding: 0 16px;
    box-sizing: border-box;
    // top: 8px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
    padding-top: 17px;
    left: 0;
    height: 82px;
    width: 100%;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    z-index: 999;

    span {
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
    }
    .close {
      height: 32px;
      display: flex;
      // align-items: center;
    }
  }
  .video {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }
}
.radio-class-isParticipate {
  box-sizing: border-box;
  width: 100%;
  height: 55px;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
  padding: 0 12px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .radio-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    span {
      text-align: left;
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
  }
}
.vote-button {
  width: 100%;
  height: 41px;
  color: #ffffff;
}
</style>
