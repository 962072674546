<!--test.vue-->
<template>
  <div
    class="menuImg"
    :class="menuImg.isSwiping ? '' : 'swiper-no-swiping'"
    style="padding: 4px; width: auto"
  >
    <swiper
      ref="menuImgSwiper"
      v-if="menuImg.labelType === '横版'"
      :options="swiperOptions"
      :class="menuImg.position"
      class="cm-flex-start"
      :style="{
        height:
          menuImg.menuList.baseImg !== undefined
            ? menuImg.menuList.baseImg.height
            : 'calc(100% - 8px)'
      }"
    >
      <img
        class="swiper-slide"
        v-for="(item, index) in menuImg.menuList"
        :key="item.id"
        :src="menuImg.menuImgIndex === index ? item.imgSel : item.img"
        style="width: auto; height: 100%; flex-grow: 0; flex-shrink: 0"
      />
    </swiper>
    <swiper
      ref="menuImgSwiper"
      v-if="menuImg.labelType === '竖版'"
      :options="swiperOptions"
      :class="menuImg.position"
      class="cm-flex-column"
      style="height: calc(100% - 8px)"
      :style="{
        width:
          menuImg.menuList.baseImg !== undefined
            ? menuImg.menuList.baseImg.width
            : 'calc(100% - 0px)'
      }"
    >
      <img
        class="swiper-slide"
        v-for="(item, index) in menuImg.menuList"
        :key="item.id"
        :src="menuImg.menuImgIndex === index ? item.imgSel : item.img"
        style="width: 100%; height: auto; flex-grow: 0; flex-shrink: 0"
      />
    </swiper>
  </div>
</template>

<script>
import { randomStr } from '@/util/common';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
export default {
  name: 'QkMenuImg', // 这个名字很重要，它就是未来的标签名<qk-text></qk-text>
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    menuImg: {
      type: Object,
      default: () => ({
        labelType: '横版',
        isSwiping: false,
        menuList: [
          {
            // 全局导航
            checked: false,
            id: randomStr(10),
            img: '',
            imgSel: '',
            // 参考图信息
            baseImg: {
              width: 0,
              height: 0,
              index: 0,
              filed: ''
            },
            label: '菜单1',
            isInputShow: false,
            linkName: '',
            editForm: {
              name: '菜单1',
              color: '#ffffff',
              colorSel: '',
              radio: '0',
              select0: { label: '', value: '' },
              select1: { label: '', value: '' },
              select2: { label: '', value: '' },
              select4: [
                {
                  pageUuid: '',
                  slidUuid: '',
                  ImgUuid: ''
                },
                {
                  pageUuid: '',
                  slidUuid: '',
                  ImgUuid: ''
                }
              ]
            }
          }
        ],
        input: '经典菜单',
        fontSize: 12,
        color: '',
        menuType: '',
        menuIndex: 0,
        viewType: '',
        menuImgIndex: 0,
        positionOptions: [
          { value: 'cm-flex-center', label: '居中' },
          { value: 'cm-flex-start', label: '左对齐' },
          { value: 'cm-flex-end', label: '右对齐' }
        ],
        position: 'cm-flex-start',
        swiperOptions: {
          direction: 'horizontal'
        }
      })
    }
  },
  data() {
    let vm = this;
    return {
      translateOld: 0,
      swiperOptions: {
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        freeMode: true,
        freeModeMomentum: false,
        resistanceRatio: 0,
        slidesPerView: 'auto',
        autoplay: false,
        loop: false,
        direction: 'horizontal',
        grabCursor: true,
        on: {
          imagesReady: function() {
            if (
              vm.menuImg.menuImgIndex === -1 &&
              vm.$refs.menuImgSwiper.$swiper
            ) {
              vm.$refs.menuImgSwiper.$swiper.slideTo(0, 0, false);
            }
          },
          tap: function() {
            // if (this.clickedIndex === undefined || vm.menuImg.menuImgIndex === this.clickedIndex) {
            if (this.clickedIndex === undefined) {
              return false;
            }
            vm.menuImg.menuImgIndex = this.clickedIndex;
            vm.$emit('menuClick', vm.menuImg.menuList[this.clickedIndex].id);
          },
          touchMove: function() {
            if (vm.translateOld !== this.getTranslate()) {
              vm.translateOld = this.getTranslate();
              vm.$emit('handleSlide', { translate: this.getTranslate() });
            }
          }
        }
      }
    };
  },
  watch: {
    'menuImg.labelType'() {
      this.$refs.menuImgSwiper.$swiper.changeDirection(
        this.menuImg.swiperOptions.direction
      );
    }
  },
  created() {
    if (this.menuImg.swiperOptions && this.menuImg.swiperOptions.direction) {
      this.swiperOptions['direction'] = this.menuImg.swiperOptions.direction;
    }
  }
};
</script>

<style scoped></style>
