<template>
  <div class="container">
    <div v-if="titleSet.mainTitle.show" class="main-title" :style="{ color: titleSet.mainTitle.color }">
      {{ titleSet.mainTitle.value }}
    </div>
    <div v-if="titleSet.subTitle.show" class="sub-title" :style="{ color: titleSet.subTitle.color }">
      {{ titleSet.subTitle.value }}
    </div>
    <div class="form-box">
      <div class="input-items">
        <input
          class="input-ele"
          type="text"
          v-model.trim="formData.name"
          placeholder="请输入姓名"
          maxlength="8"
          :style="{
            'background-color': colorSet.inputBgColor,
            'border-color': colorSet.inputBorderColor
          }"
        />
      </div>
      <div class="input-items">
        <input
          class="input-ele"
          type="number"
          v-model="formData.phone"
          placeholder="请输入手机号"
          maxlength="20"
          :style="{
            'background-color': colorSet.inputBgColor,
            'border-color': colorSet.inputBorderColor
          }"
        />
      </div>
      <div class="input-items">
        <input
          class="input-ele"
          type="text"
          v-model="formData.verify"
          placeholder="请输入验证码"
          maxlength="8"
          :style="{
            'background-color': colorSet.inputBgColor,
            'border-color': colorSet.inputBorderColor
          }"
        />
        <span class="verify-text" :style="{ color: colorSet.mainColor }" @click="getVerify">{{ verifyText }}</span>
      </div>
    </div>
    <van-button
      type="primary"
      :loading="btnLoading"
      loading-type="spinner"
      class="submit-btn"
      :style="{
        'background-color': colorSet.mainColor,
        'border-color': colorSet.mainColor
      }"
      @click="submitData"
      >提交</van-button
    >
  </div>
</template>
<script>
import api from '@/api/h5page';
import { bus } from '@/util/bus';
import { queryUrl } from '@/util/common';
import trackFn from '@/util/trackFn';
export default {
  name: 'QkForm',
  data() {
    return {
      mainTitle: '',
      subTitle: '',
      showMainTitle: true,
      showSubTitle: true,
      formData: {
        name: '',
        phone: '',
        verify: ''
      },
      verifyText: '获取验证码',
      btnLoading: false,
      timer: null,
      isPreview: false
    };
  },
  props: {
    form: {
      type: Object,
      default: () => {
        return {
          name: '',
          phone: '',
          verify: ''
        };
      }
    },
    titleSet: {
      type: Object,
      default: () => {
        return {
          mainTitle: {},
          subTitle: {}
        };
      }
    },
    colorSet: {
      type: Object,
      default: () => {
        return {};
      }
    },
    fnSet: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {},
  created() {
    let params = queryUrl();
    if (params.from && params.from === 'draft') {
      this.isPreview = true;
    } else if (params.from && params.from === 'preview') {
      this.isPreview = true;
    } else {
      this.isPreview = false;
    }
  },
  methods: {
    async getVerify() {
      if (this.isPreview) {
        this.$toast('预览下无法继续操作');
        return;
      }
      let reg = /^1\d{10}$/;
      if (!this.formData.phone) {
        this.$toast.fail('请输入手机号');
        return;
      }
      if (!reg.test(this.formData.phone)) {
        this.$toast.fail('请输入正确的手机号');
        return;
      }
      if (this.timer) {
        return;
      }
      try {
        await api.getMessageVerify({
          phone: this.formData.phone
        });
        let time = 60;
        this.verifyText = `${time}S后重新获取`;
        this.timer = setInterval(() => {
          if (time === 0) {
            this.verifyText = `获取验证码`;
            this.timer = clearInterval(this.timer);
          } else {
            time--;
            this.verifyText = `${time}S后重新获取`;
          }
        }, 1000);
      } catch (error) {
        this.$toast(error.msg || '网络错误');
      }
    },
    async submitData() {
      if (this.isPreview) {
        this.$toast('预览下无法继续操作');
        return;
      }
      let paramsData = JSON.parse(sessionStorage.getItem('USER-PARAMS-DATA'));
      let reg = /^1\d{10}$/;
      if (!this.formData.name) {
        this.$toast.fail('请输入姓名');
      } else if (!this.formData.phone) {
        this.$toast.fail('请输入手机号');
      } else if (!reg.test(this.formData.phone)) {
        this.$toast.fail('请输入正确的手机号');
      } else if (!this.formData.verify) {
        this.$toast.fail('请输入验证码');
      } else {
        try {
          const result = await api.submitFormData({
            entityId: paramsData.params.cmeId,
            customerId: paramsData.user.id,
            cusName: this.formData.name,
            phone: this.formData.phone,
            code: this.formData.verify,
            type: paramsData.params.type,
            shareId: paramsData.params.shareId,
            url: paramsData.params.url,
            extField: JSON.stringify(paramsData.params),
            shareTraceId: paramsData.params.shareTraceId
          });
          // console.log(result);
          if (result.code === '0') {
            // 触发埋点 提交表单
            let trackParams = JSON.parse(sessionStorage.getItem('trackParams'));
            trackFn.h5SubmitTrace(paramsData, trackParams);
            if (this.fnSet.submitType === 'skip') {
              bus.$emit('skipPage', {
                uuid: this.fnSet.linkId,
                index: this.fnSet.linkIndex
              });
            } else {
              this.$toast('提交成功');
            }
          }
        } catch (error) {
          this.$toast(error.msg || '网络错误');
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
input {
  border: none;
  background: none;
  outline: none;
  -webkit-appearance: none;
  -webkit-user-select: text;
  user-select: text;
}
.el-form-item {
  margin-bottom: 16px;
  position: relative;
}
input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}
.input-items {
  position: relative;
  margin-bottom: 16px;
  box-sizing: border-box;
  // padding: 0 1px;
  .input-ele {
    width: 100%;
    height: 40px;
    padding: 12px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #dadada;
    outline: none;
  }
}
.verify-text {
  position: absolute;
  right: 12px;
  top: 12px;
  font-size: 12px;
  color: #4d78ff;
}
.submit-btn {
  width: 100%;
  border-radius: 2px;
}
.main-title {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  line-height: 22px;
  text-align: left;
  margin-bottom: 6px;
}
.sub-title {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 14px;
  text-align: left;
  margin-bottom: 20px;
}
</style>
