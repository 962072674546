var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menuImg",class:_vm.menuImg.isSwiping ? '' : 'swiper-no-swiping',staticStyle:{"padding":"4px","width":"auto"}},[(_vm.menuImg.labelType === '横版')?_c('swiper',{ref:"menuImgSwiper",staticClass:"cm-flex-start",class:_vm.menuImg.position,style:({
      height:
        _vm.menuImg.menuList.baseImg !== undefined
          ? _vm.menuImg.menuList.baseImg.height
          : 'calc(100% - 8px)'
    }),attrs:{"options":_vm.swiperOptions}},_vm._l((_vm.menuImg.menuList),function(item,index){return _c('img',{key:item.id,staticClass:"swiper-slide",staticStyle:{"width":"auto","height":"100%","flex-grow":"0","flex-shrink":"0"},attrs:{"src":_vm.menuImg.menuImgIndex === index ? item.imgSel : item.img}})}),0):_vm._e(),(_vm.menuImg.labelType === '竖版')?_c('swiper',{ref:"menuImgSwiper",staticClass:"cm-flex-column",class:_vm.menuImg.position,staticStyle:{"height":"calc(100% - 8px)"},style:({
      width:
        _vm.menuImg.menuList.baseImg !== undefined
          ? _vm.menuImg.menuList.baseImg.width
          : 'calc(100% - 0px)'
    }),attrs:{"options":_vm.swiperOptions}},_vm._l((_vm.menuImg.menuList),function(item,index){return _c('img',{key:item.id,staticClass:"swiper-slide",staticStyle:{"width":"100%","height":"auto","flex-grow":"0","flex-shrink":"0"},attrs:{"src":_vm.menuImg.menuImgIndex === index ? item.imgSel : item.img}})}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }