<template>
  <div class="container" :style="h5Data.pageCommonStyle" id="h5">
    <!-- 聊一聊 -->
    <template v-if="hasCard && cardId">
      <fixed-button :cardInfo="cardInfo" :paramsData="this.wxData" :trackData="this.trackParams" :contentType="'h5'" style="z-index:999999"></fixed-button>
    </template>
    <div v-if="noData" class="no-data w-100 h-100">
      <img src="@/assets/images/no-data.png" alt="" class="no-data-img" />
      <div class="no-data-text">暂无数据</div>
    </div>

    <swiper v-else :options="swiperOption" class="page-swiper" ref="pageSwiper">
      <swiper-slide v-for="(page, i) in pages" :key="i" :class="{ 'stop-swiping': commonConfig.isAllDisabledScroll || page.config.isDisableCurrentPageScroll }" style="overflow: hidden;transform:translate3d(0,0,0);  overflow:hidden;">
        <div class="w-100 h-100 slide-scroll" :class="{ 'in-iframe': inIframe }" ref="slideWrapper" @touchstart="handleAnStart(page.type)" @touchmove="handleAnMove(page.type, i)" @scroll="handleLongScroll(page.commonStyle.pageBg.pageH, page.type, i)">
          <div
            :class="{ 'stop-swiping': page.commonStyle.pageBg.pageH > innerHeight }"
            :style="{
              width: page.commonStyle.pageBg.pageW + 'px',
              height: page.commonStyle.pageBg.pageH + 'px',
              backgroundColor: page.commonStyle.backgroundColor,
              backgroundImage: 'url(' + h5Data.pageCommonStyle.backgroundImage + ')'
            }"
            style="background-size: 100% auto; background-repeat: no-repeat;    position: relative;overflow:hidden"
          >
            <!--页面内容区域-->
            <componentsTemplate ref="componentsTemplateEle" v-for="(item, index) in page.elements" @handleElementClick="handleElementClick(item)" :element="getElement(item, index, i)" :style="{ ...getCommonStyle(item.commonStyle, scalingRatio, item), top: fixedH(item, i), width: fixedWidth(item) }" :key="index" :activePageIndex="activePageIndex" :pageIndex="i"> </componentsTemplate>
            <!-- <componentsTemplate ref="componentsTemplateEle" v-for="(item, index) in page.elements" @handleElementClick="handleElementClick(item)" :element="item" :style="getCommonStyle(item.commonStyle, scalingRatio, item)" :key="index"> </componentsTemplate> -->
            <img v-if="page.commonStyle.backgroundImage" v-lazy="page.commonStyle.backgroundImage" style="object-fit: fill; max-width: 100%" />
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <!-- loading -->
    <loading :loadingAnimate="commonConfig.loadingAnimate" v-if="pageLoading || apiLoading"></loading>
    <!-- 启动页 -->
    <!-- v-if="pageLoading || apiLoading" -->
    <starPage :commonConfig="commonConfig" v-if="pageLoading || apiLoading"></starPage>
    <!-- 音乐 -->
    <!-- <audio ref="audio" v-if="commonConfig.musicOn && !activitySrc" :src="commonConfig.musicUrl" controls="controls" :autoplay="commonConfig.autoPlay" :loop="commonConfig.cyclePlay" hidden></audio> -->
    <audio ref="audio" v-if="commonConfig.musicOn" :src="commonConfig.musicUrl" controls="controls" :autoplay="commonConfig.autoPlay" :loop="commonConfig.cyclePlay" hidden></audio>
    <!-- 音乐播放按钮 -->
    <div class="audio-button" v-if="commonConfig.musicOn">
      <div :class="playFlag ? 'rotate' : ''" @click="audioClick">
        <svg-icon :icon-name="playFlag ? 'yinyue' : 'guanbiyinyue'" :iconWidth="svgInfo(16, 16).width" :iconHeight="svgInfo(16, 16).height"></svg-icon>
      </div>
    </div>
    <!-- 活动Iframe -->
    <!-- <iframe :src="activitySrc" frameborder="0" v-if="activitySrc" width="100%" height="100%" class="iframe-class"></iframe> -->
    <!-- 活动 返回 -->

    <!-- 弹窗 -->
    <van-popup
      v-model="isShowDialog"
      v-if="isShowDialog"
      ref="popup"
      :overlay-style="{
        backgroundColor: dialogPage.commonStyle.backgroundColor
      }"
    >
      <div
        :style="{
          width: dialogPage.commonStyle.pageBg.pageW + 'px',
          height: dialogPage.commonStyle.pageBg.pageH + 'px'
        }"
        style="background-size: 100% auto; background-repeat: no-repeat"
        @click.self="backClck(dialogPage)"
      >
        <!--页面内容区域-->
        <componentsTemplate ref="popupComponentsTemplateEle" v-for="(item, index) in dialogPage.elements" @handleElementClick="handleElementClick(item)" :element="item" :style="{ ...getCommonStyle(item.commonStyle, scalingRatio, item) }" :key="index"> </componentsTemplate>
      </div>
    </van-popup>
  </div>
</template>

<script>
// import { queryUrl } from '@/util/common';
// import { swiperAnimateCache, swiperAnimate } from '@/assetsH5/js/swiper.animate.js';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import componentsTemplate from '@/componentsH5/components-template';
import { ImagePreview } from 'vant';
import api from '@/api/h5page';
import wxFn from '@/util/wxFn';
import Common from '@/api/common';
import { bus } from '@/util/bus';
import trackFn from '@/util/trackFn';
import { isAndroid } from '@/util/util';
import openDialog from '@/mixins/openDialog.js';
import createdFont from '@/mixins/createdFont.js';
export default {
  name: 'h5Page',
  components: {
    Swiper,
    SwiperSlide,
    componentsTemplate
  },
  mixins: [openDialog, createdFont],
  provide() {
    return {
      fatherNode: this
    };
  },
  data() {
    return {
      innerHeight: window.innerHeight,
      playFlag: true, // 播放音乐的状态
      pageLoading: true,
      apiLoading: true,
      swiperOption: {
        // 开启循环模式
        loop: false,
        noSwiping: true,
        noSwipingClass: 'stop-swiping',
        // 修改swiper自己或子元素时，自动初始化swiper
        observer: true,
        // 修改swiper的父元素时，自动初始化swiper
        observeParents: true,
        // 子slide更新时，swiper是否更新。默认为false不更新。
        observeSlideChildren: true,
        // 轮播方向
        direction: 'vertical',
        // 切换效果
        effect: 'slide',
        // fade效果参数 淡入淡出
        // fadeEffect: {
        //   crossFade: true
        // },
        initialSlide: 0,
        // 锚点位置
        paginationPosition: 'pagination-b',
        // 关闭滑动效果
        followFinger: false,
        // 滑动比例
        touchRatio: 1.5,
        // 鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        grabCursor: true,
        // Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        setWrapperSize: true,
        // 开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
        mousewheel: true,
        // 高度设置，占满设备高度
        height: window.innerHeight,
        // 抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。本业务需要
        resistanceRatio: 0,
        freeMode: false,
        // 禁用free模式下的动量反弹
        freeModeMomentumBounce: false
      },
      scalingRatio: 1,
      activeIndexOld: 0,
      h5Data: {},
      noData: false,
      shareTitle: '',
      shareSummary: '',
      shareImg: '',
      activePageIndex: 0, // 当前页面索引值
      commonConfig: {
        bgObj: {
          name: 'bg0', // 启动页 第一个默认是个纯色背景
          bgColor: 'rgb(208, 211, 208)',
          url: '' // 背景图片
        }
      }, // 公共配置
      timer: null,
      trackParams: {}, // 埋点需要的参数
      wxData: {}, // 授权相关的信息
      startY: 0, // 用来计算是上滑还是下滑
      anStartY: 0,
      isCanPlay: false, // 还是否播放
      hasCard: false,
      cmeId: '',
      cardId: '',
      extField: '',
      cardInfo: {},
      inIframe: false,
      isShowDialog: false, // 是否显示弹窗
      dialogPage: {}, //弹窗页信息
      fz: 0,
      elementsUuids: [] // 缓存在本地的元素id
      // activitySrc: ''
    };
  },
  async created() {
    window.self === window.top ? (this.inIframe = false) : (this.inIframe = true);
    // 根据参数获取数据
    // let params = queryUrl();
    let params = this.$route.query;
    if (params.from && params.from === 'draft') {
      // 预览草稿
      await this.initPreviewData(params.cmeId);
    } else if (params.from && params.from === 'preview') {
      // 预览发布内容
      await this.initPreviewData(params.cmeId);
    } else {
      // 需要走授权
      await this.initData(params.cmeId);
    }

    this.createdFont();
  },
  mounted() {
    this.monitorSubmit();
    this.initFrame();
    this.initRem();
  },
  updated() {
    this.preloadImg();
  },
  methods: {
    initRem() {
      let windowWidth = document.documentElement.clientWidth;
      this.fz = (windowWidth / 7.5) * 2;
      document.documentElement.style.fontSize = this.fz + 'px';
    },
    // 获取名片信息
    async getCard() {
      try {
        const params = {
          cardId: this.cardId,
          cmeId: this.cmeId,
          extField: this.extField
        };
        this.cardInfo = await Common.getCardInfo(params);
        this.$store.commit('app/SET_CARD_DATA', this.cardInfo);
      } catch (error) {
        this.$toast(error.msg || '请求异常');
      }
    },
    /**
     * @description: 监听iframe
     * @param {*}
     * @return {*}
     */
    initFrame() {
      window.addEventListener('message', e => {
        if (window.location.origin === e.origin) {
          return;
        }
        console.log(e, 'origin');
        // 翻页
        switch (e.data.type) {
          case 'slide':
            {
              let index = this.activePageIndex;
              if (e.data.data === 'add') {
                if (this.commonConfig.isLoop && index == this.pages.length - 1) {
                  index = 0;
                  this.$refs.pageSwiper.$swiper.slideTo(index, 0, true);
                } else {
                  index += 1;
                  this.$refs.pageSwiper.$swiper.slideTo(index, 800, true);
                }
              } else {
                if (this.commonConfig.isLoop && index == 0) {
                  index = this.pages.length - 1;
                  this.$refs.pageSwiper.$swiper.slideTo(index, 0, true);
                } else {
                  index -= 1;
                  this.$refs.pageSwiper.$swiper.slideTo(index, 800, true);
                }
              }
            }
            break;
          case 'reset':
            {
              // 刷新页面
              window.location.reload();
              // if (e.data.data) {
              //   this.$nextTick(() => {
              //     this.$refs.pageSwiper.$swiper.slideTo(e.data.data-1, 0, true);
              //   });
              // }
            }
            break;
          case 'closeAudio':
            // 关闭音乐播放
            this.$nextTick(() => {
              this.isCanPlay = true;
              this.$refs.audio && this.$refs.audio.pause();
            });
            break;
          default:
            break;
        }
      });
    },
    getCommonStyle(styleObj, scalingRatio = 1, item) {
      let needUnitStr = ['width', 'height', 'top', 'bottom', 'left', 'paddingTop', 'paddingLeft', 'paddingRight', 'paddingBottom', 'marginTop', 'marginLeft', 'marginRight', 'marginBottom', 'borderWidth', 'fontSize', 'borderRadius', 'letterSpacing', 'lineHeight'];
      let style = {};
      for (let key in styleObj) {
        if (styleObj[key] === null) {
          delete styleObj[key];
        }
        if (needUnitStr.includes(key)) {
          style[key] = styleObj[key] * scalingRatio + 'px';
          if (key === 'fontSize') {
            style[key] = styleObj[key] / 100 + 'rem';
          }
        } else {
          style[key] = styleObj[key];
        }
      }
      // 投票组件不需要旋转
      if (item.elName === 'qk-vote') {
        style.transform = 'unset';
      } else {
        style.transform = `rotate(${style.rotate}deg)`;
      }
      style.backgroundImage = style.backgroundImage ? `url(${style.backgroundImage})` : '';
      return style;
    },
    async initWx(cmeId, share = true) {
      const wxData = await wxFn.init(['onMenuShareTimeline', 'onMenuShareAppMessage', 'getLocation', 'openLocation'], cmeId);
      this.wxData = wxData;
      wx.ready(() => {
        if (this.playFlag) {
          this.$refs.audio.play();
        }
      });

      // 这里写微信的接口
      if (share) {
        this.cardId = wxData.params.saleId ? wxData.params.saleId : '';
        this.cmeId = wxData.params.cmeId;
        this.extField = JSON.stringify(wxData.params);
        if (this.cardId) {
          this.getCard();
        }
        this.trackParams.timeRnd = Date.now(); // 记录进入页面时的 当前时间
        wxFn.initWxShareShare(wxData, this.shareTitle, this.shareSummary, this.shareImg, 'h5', this.trackParams);
        // trackFn.h5ViewTrace(wxData, this.trackParams, 'IN');
        trackFn.contentViewTrace(wxData, this.trackParams, 'IN', 'h5');
        //注册OUT事件埋点（注册事件，sdk进行浏览器关闭事件上报 ）
        // trackFn.viewOutTrace('leaflets_read', wxData, {
        //   leaflets_id: this.trackParams.entityId,
        //   leaflets_title: this.trackParams.title,
        // });
        // trackFn.contentOutViewTrace(wxData, this.trackParams, 'h5');
      }
    },
    async initPreviewData(data) {
      try {
        const result = await api.getPreviewData(data, true);
        if (result.content) {
          this.noData = false;
          this.h5Data = JSON.parse(result.content);
          // console.log(this.h5Data,'----');
          this.commonConfig = this.h5Data.commonConfig;
          this.playFlag = this.commonConfig.musicOn && this.commonConfig.autoPlay;
          this.apiLoading = false;
          this.adjustView();
          this.initView();
          this.initWx(data, false);
        } else {
          this.noData = true;
        }
      } catch (error) {
        // console.error('error', error);
        this.noData = true;
        this.apiLoading = false;
        this.$toast(error.msg || '网络错误');
      }
    },
    async initData(data) {
      try {
        const result = await api.getPageData(data, true);
        document.title = result.title;
        this.shareTitle = result.title;
        this.shareSummary = result.summary;
        this.shareImg = result.image;
        this.hasCard = result.hasCard;
        this.trackParams = {
          title: result.title,
          entityId: result.entityId
        };
        sessionStorage.setItem(
          'trackParams',
          JSON.stringify({
            title: result.title,
            entityId: result.entityId
          })
        );
        this.apiLoading = false;
        if (result.content) {
          this.noData = false;
          this.h5Data = JSON.parse(result.content);
          this.commonConfig = this.h5Data.commonConfig;
          this.playFlag = this.commonConfig.musicOn && this.commonConfig.autoPlay;
          this.adjustView();
          this.initView();
          this.initWx(data);
        } else {
          this.noData = true;
        }
      } catch (error) {
        // console.log('error', error);
        this.noData = true;
        this.apiLoading = false;
        this.$toast(error.msg || '网络错误');
      }
    },
    initView() {
      let _this = this;
      if (this.$refs.pageSwiper) {
        this.$refs.pageSwiper.destroySwiper();
        _this.swiperOption['on'] = {
          init: function() {
            // console.log('init~~~~');
            // swiperAnimateCache(this); //隐藏动画元素
            // swiperAnimate(this); //初始化完成开始动画
            _this.activeIndexOld = this.activeIndex;
            _this.initAnimation('init', this.activeIndex);
          },
          transitionStart: function() {
            // console.log('startIndex' + this.activeIndex);
            _this.initAnimation('start', this.activeIndex);
          },
          transitionEnd: function() {
            _this.activePageIndex = this.activeIndex;
            // console.log('endIndex' + this.activeIndex);
            console.log('transitionEnd');
            _this.initAnimation('end', this.activeIndex);
            // 发消息给父级页面 更换当前页码
            if (window.parent) {
              window.parent.postMessage(
                {
                  messageType: 'childrenAction',
                  messageData: this.activeIndex
                },
                '*'
              );
            }
          },
          slideChange: function() {
            _this.activePageIndex = this.activeIndex;
            if (_this.pages[this.activeIndex].type === 'lang') {
              _this.$refs.slideWrapper[this.activeIndex].scrollTop = 1;
            }
            console.log('slideChange');
          },
          slideChangeTransitionStart: function() {
            // console.log('slideChangeTransitionStart');
          },
          slideChangeTransitionEnd: function() {
            // swiperAnimate(this); //每个slide切换结束时也运行当前slide动画
            // console.log('slideChangeTransitionEnd');
          },
          setTransition: function() {
            // console.log(this);
          },
          touchStart(event) {
            if (_this.pages[this.activeIndex].type === 'lang') {
              return false;
            }
            if (_this.commonConfig.isLoop) {
              // 开启循环硬记录初始位置
              _this.startY = event.pageY;
            }
          },
          touchEnd(event) {
            // 开启循环硬切换
            if (_this.commonConfig.isLoop) {
              let distance = _this.startY - event.pageY;
              let length = _this.pages.length;
              if (distance > 0) {
                //向下
                if (this.activeIndex === length - 1) {
                  _this.$refs.pageSwiper.$swiper.slideTo(0, 0, true);
                }
              }
              if (distance < 0) {
                if (this.activeIndex === 0) {
                  _this.$refs.pageSwiper.$swiper.slideTo(length - 1, 0, true);
                }
              }
            }
          }
        };
        // 开启自动翻页
        if (this.commonConfig.isAutoScroll) {
          _this.swiperOption.autoplay = {
            delay: _this.commonConfig.time * 1000,
            stopOnLastSlide: !_this.commonConfig.isLoop //如果设置为true，当切换到最后一个slide时停止自动切换。（loop模式下无效）
          };
        }
        this.$refs.pageSwiper.initSwiper();
      }
    },
    initAnimation(type, index) {
      let getUUid = (arr = [], pageIndex) => {
        if (this.pages[pageIndex] && this.pages[pageIndex].elements) {
          this.pages[pageIndex].elements.forEach(element => {
            arr.push(element.uuid);
          });
        }
        return arr;
      };
      let uuids = getUUid([], type === 'end' ? this.activeIndexOld : index);
      let elements = this.$refs.componentsTemplateEle;

      elements &&
        elements.forEach(ct => {
          if (uuids.includes(ct.element.uuid)) {
            switch (type) {
              case 'end':
                // 动画--结束的时候清除掉上一个页面的动画
                if (ct.element.animations.length) {
                  ct.finalAnimations();
                }
                // 视频
                if (ct.element.elName === 'qk-video') {
                  let video = ct.$children;
                  video.forEach(item => {
                    item.pauseVideo();
                  });
                }
                break;
              default:
                // init、start
                // 动画
                if (ct.element.animations.length) {
                  ct.playAnimations();
                }
                // 视频
                if (ct.element.elName === 'qk-video') {
                  let video = ct.$children;
                  video.forEach(item => {
                    item.playVideo();
                  });
                }
                break;
            }
          }
        });
      if (type === 'end') {
        this.activeIndexOld = index;
      }
    },
    async adjustView() {
      this.h5Data.pages &&
        this.h5Data.pages.forEach(item => {
          // 先修改页面宽高
          // let scale = item.commonStyle['pageBg']['pageH'] / item.commonStyle['pageBg']['pageW'];
          let scaleView = innerHeight / innerWidth;
          let wrapperHeight = item.commonStyle['pageBg']['pageH'];
          item.commonStyle['pageBg']['pageW'] = (window.innerWidth * item.commonStyle['pageBg']['pageW']) / 375;
          if (item.type === 'normal' || item.type === 'dialog') {
            item.commonStyle['pageBg']['pageH'] = item.commonStyle['pageBg']['pageW'] * scaleView;
          }
          if (item.type === 'lang') {
            // 宽高比
            let scale = 375 / item.commonStyle['pageBg']['pageH'];
            // (window.innerHeight * item.commonStyle['pageBg']['pageH']) / 724
            // 通过比例算高度

            item.commonStyle['pageBg']['pageH'] = window.innerWidth / scale;
          }
          let newWrapperHeight = item.commonStyle['pageBg']['pageH'];
          // 再修改页面里面的组件宽高
          item.elements.forEach(innerItem => {
            let innerScale = innerItem.commonStyle['height'] / innerItem.commonStyle['width'];
            if (innerItem.elName === 'qk-image') {
              innerItem.commonStyle['height'] = (innerItem.commonStyle['height'] / wrapperHeight) * newWrapperHeight;
              innerItem.commonStyle['width'] = innerItem.commonStyle['width'] * (window.innerWidth / 375);
            } else {
              innerItem.commonStyle['width'] = innerItem.commonStyle['width'] * (window.innerWidth / 375);
              innerItem.commonStyle['height'] = innerItem.commonStyle['width'] * innerScale;
              innerItem.commonStyle['lineHeight'] = (innerItem.commonStyle['lineHeight'] / 724) * window.innerHeight;
            }
            innerItem.commonStyle['left'] = window.innerWidth * (innerItem.commonStyle['left'] / 375);
            if (innerItem.isPosition) {
              innerItem.commonStyle['bottom'] = (innerItem.commonStyle['bottom'] / wrapperHeight) * newWrapperHeight;
            } else {
              innerItem.commonStyle['top'] = (innerItem.commonStyle['top'] / wrapperHeight) * newWrapperHeight;
            }
          });
        });
    },
    handleElementClick(item) {
      if (item.events.length) {
        const eventList = {
          makeCall: data => {
            this.handleCallPhone(data);
          },
          skipPage: data => {
            this.handleSkipPage(data);
          },
          skipIframe: data => {
            this.handleSkipLink(data);
          },
          amplification: () => {
            // 查看图片需要设置，传item
            this.handleViewImg(item);
          },
          skipActivity: data => {
            // 拉取缓存信息
            let usercatch = localStorage.getItem(this.$route.query.cmeId);
            if (usercatch && JSON.parse(usercatch).length) {
              // 有缓存
              this.elementsUuids = JSON.parse(usercatch);
              !this.elementsUuids.includes(item.uuid) && this.elementsUuids.push(item.uuid);
            } else {
              // 第一次进入页面并且是新用户
              this.elementsUuids.push(item.uuid);
            }
            // 缓存用户信息
            localStorage.setItem(this.$route.query.cmeId, JSON.stringify(this.elementsUuids));
            this.elementsUuids = [];
            // this.activitySrc = data.linkIframeSrc;
            if (this.$route.query.from) {
              window.location.href = `${data.linkIframeSrc}&from=${this.$route.query.from}`;
            } else {
              window.location.href = data.linkIframeSrc;
            }

            // window.location.href =
          },
          openDialog: data => {
            this.openDialog(data);
            // this.activitySrc = data.linkIframeSrc;
          },
          skipMap: data => {
            this.openMap(data);
          }
        };
        const key = item.events[0].eventType;
        const data = item.events[0];
        eventList[key](data);
        let eventArr = ['amplification', 'openDialog'];
        // 除了 图片放大 不通过点击蒙层关闭弹窗
        if (!eventArr.includes(key)) {
          this.backClck(this.dialogPage);
        }
      }
    },
    // 处理拨打电话事件
    handleCallPhone(data) {
      window.location.href = `tel://${data.phoneNum}`;
    },
    // 处理跳转页面事件
    handleSkipPage(data) {
      let index = this.pages.findIndex(item => {
        return item.uuid === data.linkId;
      });
      if (index < 0) {
        this.$toast('跳转页面已被删除');
      } else {
        this.$refs.pageSwiper.$swiper.slideTo(index, 800, true);
      }
    },
    // 处理跳转外链事件
    handleSkipLink(data) {
      console.log(data);
      window.location.href = data.linkIframeSrc;
    },
    // 处理查看原图事件
    handleViewImg(data) {
      let src = data.propsValue.imageSrc;
      let event = data.events[0];
      if (event.switchType === 'page') {
        ImagePreview({
          images: [src],
          showIndex: false
        });
      } else if (event.switchType === 'button') {
        ImagePreview({
          images: [src],
          showIndex: false,
          closeable: true
        });
      }
    },
    // 表单提交后的跳转页面
    monitorSubmit() {
      bus.$on('skipPage', val => {
        this.$refs.pageSwiper.$swiper.slideTo(val.index, 800, true);
      });
    },
    // 长页面滑动
    handleLongScroll(height, type, index) {
      if (this.commonConfig.isAllDisabledScroll) {
        return;
      }
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (type === 'normal') {
        return;
      }
      let $event = event;
      this.$bus.$emit('langScroll', { scrollTop: $event.target.scrollTop, index });
      let _this = this;
      let handle = function() {
        let scrollTop = Math.ceil($event.target.scrollTop);
        let diff = Math.floor(height - window.innerHeight);

        if (scrollTop >= diff && index < _this.pages.length - 1) {
          _this.$refs.pageSwiper.$swiper.slideTo(index + 1, 400, true);
        } else if (scrollTop >= diff && index == _this.pages.length - 1 && _this.commonConfig.isLoop) {
          _this.$refs.pageSwiper.$swiper.slideTo(0, 0, true);
        } else if (scrollTop <= 0 && index == 0 && _this.commonConfig.isLoop) {
          _this.$refs.pageSwiper.$swiper.slideTo(_this.pages.length - 1, 0, true);
        } else if (scrollTop <= 0 && index !== 0) {
          _this.$refs.pageSwiper.$swiper.slideTo(index - 1, 400, true);
        }
      };
      this.timer = setTimeout(handle, 30);
    },
    audioClick() {
      if (this.isCanPlay) {
        return;
      }
      let audio = this.$refs.audio;
      if (audio) {
        if (this.playFlag) {
          audio.pause();
          this.playFlag = false;
        } else {
          audio.play();
          this.playFlag = true;
        }
      }
    },
    // 监听图片加载
    preloadImg() {
      let imgs = document.getElementsByTagName('img');
      let total = imgs.length;
      let count = 0;
      let _this = this;
      if (total) {
        for (let i = 0; i < imgs.length; i++) {
          imgs[i].onload = () => {
            count++;
            if (total === count) {
              _this.pageLoading = false;
            }
          };
          // 也要展示页面
          setTimeout(() => {
            _this.pageLoading = false;
          }, 300);
        }
      } else {
        this.pageLoading = false;
      }
    },
    // 兼容安卓滑动问题
    handleAnStart(type) {
      if (isAndroid() && type === 'lang') {
        this.anStartY = event.touches[0].pageY;
      }
    },
    handleAnMove(type, i) {
      if (isAndroid() && type === 'lang') {
        let ele = this.$refs.slideWrapper[i];
        if (ele.scrollTop <= 0) {
          console.log(123);
          let distance = this.anStartY - event.touches[0].pageY;
          if (distance <= 0 && i != 0) {
            event.preventDefault();
            this.$refs.pageSwiper.$swiper.slideTo(i - 1, 300, true);
          }
        }
      }
    }
    // goBack() {
    //   this.activitySrc = '';
    // }
  },
  computed: {
    svgInfo() {
      return function(width, height) {
        let proportion = window.innerWidth / this.h5Data.pageCommonStyle.width;
        return {
          width: width * proportion,
          height: height * proportion
        };
      };
    },
    fixedH() {
      return function(item, index) {
        if (item.isFixed && this.activePageIndex === index) {
          return item.commonStyle.top + index * window.innerHeight + 'px';
        } else if (item.isFixed && this.activePageIndex !== index) {
          return 'unset';
        } else {
          return item.commonStyle.top + 'px';
        }
      };
    },
    fixedWidth() {
      return function(item) {
        if (item.isFixed) {
          if (this.inIframe) {
            return item.commonStyle.width - 5 + 'px';
          } else {
            return item.commonStyle.width + 'px';
          }
        } else {
          return item.commonStyle.width + 'px';
        }
      };
    },
    pages() {
      return Object.keys(this.h5Data).length && this.h5Data.pages.filter(i => i.type !== 'dialog');
    },
    // 获取元素
    getElement() {
      return function(item, index, pageIndex) {
        let userCatch = this.$route.query.cmeId && localStorage.getItem(this.$route.query.cmeId);
        // 有缓存
        if (userCatch && JSON.parse(userCatch).length) {
          let obj;
          JSON.parse(userCatch).forEach(i => {
            obj = JSON.parse(JSON.stringify(item));
            if (i === item.uuid && item.events[0].isReplaceImg) {
              // 说明用户点击过按钮
              obj.propsValue.imageSrc = obj.events[0].replaceImg;
              this.h5Data.pages[pageIndex].elements[index] = obj;
            }
          });
          return obj;
        } else {
          return item;
        }
      };
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .no-data-img {
      width: 100px;
      height: 100px;
    }
    .no-data-text {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .slide-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 9;
    cursor: pointer;
  }
  .in-iframe {
    &::-webkit-scrollbar {
      width: 8px;
      background-color: rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 8px;
    }
  }
  .audio-button {
    width: 36px;
    height: 30px;
    background: rgba(0, 0, 0, 0.64);
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.07);
    border-radius: 0px 20px 20px 0px;
    position: absolute;
    left: 0;
    top: 46px;
    z-index: 99;
    text-align: center;
    line-height: 30px;
  }
  .call-phone-ele {
    position: relative;
    visibility: hidden;
  }
  .iframe-class {
    position: absolute;
    top: 0;
    z-index: 9;
  }
  .back-icon {
    position: absolute;
    top: 17px;
    left: 18px;
    z-index: 9;
  }
}
/deep/ .van-popup--center {
  background: transparent;
  overflow: hidden;
}
.w-100 {
  width: 100vw;
}
.h-100 {
  height: 100vh;
}
#map {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 9999;
}
</style>
